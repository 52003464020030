@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

.menu-item.active {
  color: #0f223c;
  background-color: #ffffff;
}

.tooltip:hover .tooltip-text {
  display: block;
}

.tooltip-text {
  display: none;
}

.tooltip-text::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.ReactModal__Overlay .ReactModal__Overlay--after-open {
  z-index: 999999999 !important;
}
input.amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input.amount {
  -moz-appearance: textfield;
}
